/**
 * NOTE: turbo-cable-stream-source monkeypatch
 * 
 * In Telescope we use a lot of permanent turbo elements that subscribes to an action cable channel via turbo-stream-source.
 * This works well most of the time, but there is a gotcha with permanent elements: they're physically removed from the old
 * body and added to the new one. This triggers the disconnected and connected callback, which unsubscribes and subscribes
 * to the channel again. This is unecessary and wasteful, which is bad on itself, but the worse part is reacting to
 * disconnections. I mean, real disconnections, and not the flash disconnect-reconnect that happens when navigating
 * to a page with the same permanent element.
 * 
 * This monkey patch introduces a new element (turbo-cable-permanent-stream-source) that is a little bit lazy to fire off
 * disconnections. After the element is removed from DOM, it waits a little bit to see if it is going to be re-added and,
 * if it is, nothing is executed. This is both more optimized and more stable to observers of the connection state.
 */
const disconnections = {}

const TurboCableStreamSourceElement = customElements.get("turbo-cable-stream-source")

class TurboCableStreamSourceElementPermanent extends TurboCableStreamSourceElement {
  async connectedCallback() {
    const disconnection = disconnections[this.#identifier()]
    if (disconnection) {
      this.subscription = disconnection.subscription
      return clearTimeout(disconnection.timeout)
    }

    console.log("connecting", this.#friendlyName())

    await TurboCableStreamSourceElement.prototype.connectedCallback.apply(this)
  }

  disconnectedCallback() {
    disconnections[this.#identifier()] = {
      subscription: this.subscription,
      timeout: setTimeout(() => {
        delete disconnections[this.#identifier()]

        console.log("disconnecting", this.#friendlyName())

        TurboCableStreamSourceElement.prototype.disconnectedCallback.apply(this)
      }, 1000)
    }
  }

  #identifier() {
    return `${this.getAttribute("channel")}:${this.getAttribute("signed-stream-name")}`
  }

  #friendlyName() {
    return this.getAttribute("friendly-name")
  }
}

customElements.define("turbo-cable-permanent-stream-source", TurboCableStreamSourceElementPermanent)

